import React from 'react'
import * as classes from '../assets/scss/item.module.scss'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import { Container, Row, Col } from 'react-bootstrap'

import Search from '../components/banya/search'
import ImagesBlock from '../components/banya/imagesBlock'
import Modules from '../components/banya/modules'
import Dops from '../components/banya/dops'

const Banya = ({ data }) => {
  //массив допов
  const dops = data.dopsJson?.dops
  //активный доп по дефолту интерьер (1)
  const [activeDop, setActiveDop] = React.useState(1)
  //создаем массив из 3х пустых массивов для добавления в них индексов чекнутых инпутов
  const [checked, setChecked] = React.useState(new Array(dops?.length).fill([]))

  //массив модулей
  const modules = data.banyaJson.modules
  //активный модуль
  const [activeModule, setActiveModule] = React.useState(modules[0])

  return (
    <Layout
      title={data.metadataJson.title}
      keywords={data.metadataJson.keywords}
      description={data.metadataJson.description}
      brcr={data.metadataJson.brcr}
    >
      <section className={classes.wrapper}>
        <Container className={`position-relative`}>
          <div className="d-md-flex justify-content-between">
            <Row id="prod" className={classes.content}>
              <Col lg={8}>
                <h1>{data.banyaJson.title}</h1>
                <ImagesBlock model={data.banyaJson.model} images={data.banyaJson.images} />
              </Col>
              <Col lg={4}>
                <h3>Описание:</h3>
                <div
                  className={classes.desc}
                  dangerouslySetInnerHTML={{ __html: data.banyaJson.description }}
                />
              </Col>
              <Col lg={11} className={`mt-3 mt-md-5`}>
                <Modules
                  modules={modules}
                  activeModule={activeModule}
                  setActiveModule={(v) => setActiveModule(v)}
                />
              </Col>
              <Col xs={12}>
                {dops && (
                  <Dops
                    dops={dops}
                    activeDop={activeDop}
                    setActiveDop={(v) => setActiveDop(v)}
                    checked={checked}
                    setChecked={(v) => setChecked(v)}
                  />
                )}
              </Col>
            </Row>
            <div>
              <Search
                banya={data.banyaJson.title}
                activeModule={activeModule}
                dops={dops}
                checked={checked}
                setActiveDop={(v) => setActiveDop(v)}
              />
            </div>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default Banya

export const query = graphql`
  query ($slug: String) {
    metadataJson(slug: { eq: $slug }) {
      title
      description
      keywords
      brcr
    }
    dopsJson(slug: { eq: $slug }) {
      dops {
        type
        items {
          name
          price
        }
      }
    }
    banyaJson(slug: { eq: $slug }) {
      title
      description
      model
      images {
        image {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, quality: 99, formats: [AUTO, WEBP])
          }
        }
      }
      modules {
        name
        price
        tabs {
          name
          items {
            name
            value
          }
        }
      }
    }
  }
`
