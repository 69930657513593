import React from 'react'
import { Link } from 'react-scroll'
import * as classes from './search.module.scss'
import Modal from '../modal'

export default function Search({ banya, activeModule, dops, checked, setActiveDop }) {
  //кнопка поиска на мобилках
  const [btnSearch, setBtnSearch] = React.useState(false)
  if (typeof window !== `undefined` && typeof document !== `undefined`) {
    document.addEventListener('scroll', () => {
      if (window.pageYOffset >= 200) {
        document.getElementById('btnSearch')?.classList.add(`d-block`)
      } else {
        document.getElementById('btnSearch')?.classList.remove(`d-block`)
      }
    })
  }
  let sendDops = []
  let prices = new Array(dops?.length).fill(0)
  dops?.map((dop, i) => {
    return dop.items.map((item, index) => {
      if (checked[i].includes(index) && checked[i].length > 0) {
        sendDops.push(item.name)
        prices[i] += item.price
      }
    })
  })
  sendDops = sendDops.join(', \n')

  return (
    <>
      <div
        id="btnSearch"
        className={btnSearch ? `${classes.btnSearch} ${classes.open}` : `${classes.btnSearch}`}
      >
        <button onClick={() => setBtnSearch(!btnSearch)}>Заказ</button>
      </div>

      <div className={btnSearch ? `${classes.search} ${classes.show}` : `${classes.search}`}>
        <h3>Оформление Заказа:</h3>
        {dops && <h4>Модуль бани:</h4>}
        <div>
          <Link to="Module" smooth={true} offset={-80} duration={500}>
            {activeModule.name} - {activeModule.price.toLocaleString('ru') + '₽'}
          </Link>
        </div>
        {dops && (
          <>
            <h4>Дополнительные опции:</h4>
            {prices?.filter((item) => item !== 0).length > 0 ? (
              dops.map((dop, index) => {
                return (
                  checked[index].length > 0 && (
                    <Link
                      to="Dops"
                      smooth={true}
                      offset={index === 1 ? -100 : -270}
                      duration={700}
                      onClick={() => setActiveDop(index)}
                      key={index}
                    >
                      <p>
                        {index === 0
                          ? `${dop.items[checked[index]].name}`
                          : `${dop.type}(${checked[index].length})`}{' '}
                        - {prices[index].toLocaleString('ru') + '₽'}
                      </p>
                    </Link>
                  )
                )
              })
            ) : (
              <Link
                to="Dops"
                smooth={true}
                offset={-100}
                duration={700}
                onClick={() => setBtnSearch(false)}
              >
                Выбрать опции
              </Link>
            )}
          </>
        )}
        <p className={classes.total}>
          Итого:{' '}
          {(activeModule.price + prices.reduce((sum, elem) => sum + elem)).toLocaleString('ru') +
            '₽'}
        </p>
        <Modal
          banya={banya}
          module={activeModule.name}
          sendDops={sendDops}
          btnStyles={{ width: '100%' }}
        >
          Заказать
        </Modal>
        <Modal
          text
          btnStyles={{ width: '100%', color: 'black', marginTop: '14px', fontSize: '24px' }}
          sendDops={sendDops}
          banya={banya}
          module={activeModule.name}
        >
          Задать вопрос
        </Modal>
      </div>
    </>
  )
}
