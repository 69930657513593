import React from 'react'
import { Row, Col } from 'react-bootstrap'
import * as classes from './modules.module.scss'

export default function Modules({ modules, activeModule, setActiveModule }) {
  //const [activeTab, setActiveTab] = React.useState(0)

  return (
    <Row id="Module">
      <Col md={6} lg={5}>
        <h3>{modules.length > 1 ? 'Выберите модуль бани' : 'Выберите модуль'}</h3>
        {modules.map((module, i) => {
          return (
            <button
              key={i}
              onClick={() => {
                setActiveModule(modules[i])
              }}
              className={
                activeModule === modules[i]
                  ? `${classes.modules} ${classes.active}`
                  : `${classes.modules}`
              }
            >
              <div>{module.name}</div>
              <div>
                {module.price === 0 ? 'По согласованию' : module.price.toLocaleString('ru') + '₽'}
              </div>
            </button>
          )
        })}
      </Col>
      <Col md={6} lg={7} className={`mt-4 mt-md-0`}>
        <h3>Характеристики модуля:</h3>
        {/* Если добавить таб с доставкой, удалить это и раскаментить код ниже и стейт */}
        {/* <div>{activeModule.tabs[0].name}</div> */}
        {activeModule.tabs[0].items.map((item, i) => {
          return (
            <div key={i} className={classes.info}>
              <div>{item.name}</div>
              <div>{item.value}</div>
            </div>
          )
        })}
        {/* {activeModule.tabs.map((tab, i) => {
          return (
            <div key={i}>
              <button onClick={() => setActiveTab(i)} className={activeTab === i ? `${classes.active}` : null}>
                {tab.name}
              </button>
              {activeTab === i &&
                tab.items.map((item, index) => {
                  return (
                    <div key={index} className={`d-flex`}>
                      <div>{item.name}</div>
                      <div>{item.value}</div>
                    </div>
                  )
                })}
            </div>
          )
        })} */}
      </Col>
    </Row>
  )
}
