import React from 'react'
import { Row, Col } from 'react-bootstrap'
import * as classes from './dops.module.scss'

export default function Dops({ dops, activeDop, setActiveDop, checked, setChecked }) {
  function handleChange(event, i, index) {
    let checkedClone = checked.slice()
    if (event.target.checked) {
      event.target.type === `radio` && (checkedClone[i] = [])
      checkedClone[i] = [...checkedClone[i], index]
      setChecked(checkedClone)
    } else {
      checkedClone[i] = checkedClone[i].filter((k) => k !== index)
      setChecked(checkedClone)
    }

    // if (typeof document !== `undefined`) {
    //   const kolobokPrice = document.getElementById("price-Обливное устройство - 'Колобок'")
    //   const dverPrice = document.getElementById('price-Стеклянная матовая дверь 8mm, 2 петли')

    //   if (event.target.value === "Обливное устройство - 'Колобок'") {
    //     event.target.checked && kolobokPrice.innerHTML !== '8 500₽'
    //       ? (dverPrice.innerHTML = '8 500₽')
    //       : (dverPrice.innerHTML = '1₽')
    //   }
    //   if (event.target.value === 'Стеклянная матовая дверь 8mm, 2 петли') {
    //     event.target.checked && dverPrice.innerHTML !== '8 500₽'
    //       ? (kolobokPrice.innerHTML = '8 500₽')
    //       : (kolobokPrice.innerHTML = '1₽')
    //   }
    // }
  }

  return (
    <div className={classes.dops} id="Dops">
      <h2>Добавьте дополнительные опции:</h2>
      <div className={classes.btns}>
        {dops.map((dop, i) => {
          return (
            <button
              key={`btn-${i}`}
              onClick={() => setActiveDop(i)}
              className={activeDop === i ? `${classes.active}` : null}
            >
              {dop.type}
              <span />
            </button>
          )
        })}
      </div>
      <div className={classes.card}>
        {dops.map((_dop, i) => {
          return (
            activeDop === i && (
              <Row key={`row-${i}`}>
                {dops[activeDop].items.map((item, index) => {
                  return (
                    <Col key={index} md={4}>
                      <input
                        className={`d-none`}
                        type={i === 0 ? `radio` : `checkbox`}
                        id={`dop-item-${item.name}`}
                        name={`1`}
                        value={item.name}
                        checked={checked[i].includes(index)}
                        onChange={(event) => handleChange(event, i, index)}
                      />
                      <label htmlFor={`dop-item-${item.name}`}>
                        <span className={classes.checkbox} />
                        <div>
                          {item.name} -{' '}
                          <span id={`price-${item.name}`}>
                            {item.price.toLocaleString('ru') + '₽'}
                          </span>
                        </div>
                      </label>
                    </Col>
                  )
                })}
              </Row>
            )
          )
        })}
      </div>
    </div>
  )
}
